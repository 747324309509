import { GoogleTagManager } from '@next/third-parties/google';
import { setContext } from '@sentry/nextjs';
import useCookie from 'app/hooks/use-cookie';
import { cookieCountries } from 'app/modules/Constants';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { Assistant, Montserrat } from 'next/font/google';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Provider, connect } from 'react-redux';
import { agreeCookieNotice, setLanguage } from '../actions/baseActions';
import { useStore } from '../store';
import '../styles/app.scss';
import '../styles/responsive.scss';

const montserrat = Montserrat({
	subsets: ['latin-ext', 'cyrillic-ext'],
	display: 'swap',
	weight: ['400', '600'],
});
const assistant = Assistant({
	subsets: ['latin-ext'],
	display: 'swap',
	weight: ['400', '500', '600', '700', '800'],
	variable: '--font-assistant',
});

export const initializeConsent = () => {
	if (typeof window !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		window.gtag =
			window.gtag ||
			function () {
				// eslint-disable-next-line prefer-rest-params
				(window.dataLayer = window.dataLayer || []).push(arguments);
			};

		window.gtag('consent', 'default', {
			functionality_storage: 'denied',
			security_storage: 'granted',
			ad_personalization: 'denied',
			ad_storage: 'denied',
			ad_user_data: 'denied',
			analytics_storage: 'denied',
			personalization_storage: 'denied',
			region: cookieCountries,
		});

		window.gtag('set', 'ads_data_redaction', true);
		window.gtag('set', 'url_passthrough', false);
	}
};

function App({ Component, pageProps }: AppProps) {
	const [renderScript, setRenderScript] = useState(false);
	const [hydrated, setHydrated] = useState(false);
	const [isSiteLoaded, setIsSiteLoaded] = useState(false);
	const [clarityUserId] = useCookie('_clck');
	const [userIdAvailable, setUserIdAvailable] = useState(false);

	const store = useStore(pageProps.initialReduxState);
	const params = useSearchParams();
	const lng = params?.get('lng') as string;

	if (lng && typeof document !== 'undefined') {
		document.documentElement.lang = lng;
	}

	useEffect(() => {
		let agreeCookieNoticeValue;
		try {
			agreeCookieNoticeValue = localStorage.getItem('cookieNotice');
			if (agreeCookieNoticeValue) {
				store.dispatch(agreeCookieNotice());
			}
		} catch (error) {
			console.error('Failed to read from localStorage, fallback to default value:', error);
		}
		initializeConsent();
		setHydrated(true);
	}, []);

	useEffect(() => {
		if (clarityUserId && !userIdAvailable) {
			setUserIdAvailable(true);

			setContext('Clarity User', {
				id: clarityUserId,
			});
		}
	}, [clarityUserId]);

	useEffect(() => {
		if (process.env.NODE_ENV !== 'development') {
			if ('serviceWorker' in navigator) {
				window.addEventListener('load', () => {
					const version = '1.3.16';
					navigator.serviceWorker
						.register(`/service-worker.js?version=${version}`)
						.then((registration) => {
							console.log('Service worker version:', version);
						})
						.catch((error) => {
							console.error('Service Worker registration failed:', error);
						});
				});
			}
		}
		if (!isSiteLoaded) {
			window.addEventListener('load', () => {
				setIsSiteLoaded(true);
				setRenderScript(!window.location.pathname.includes('/passthru'));
			});
		}
	}, []);

	if (!hydrated) {
		// Returns null on first render, so the client and server match
		return null;
	}

	return (
		<>
			<DefaultSeo
				openGraph={{
					type: 'website',
					locale: 'en_IE',
					url: process.env.NEXT_PUBLIC_SITE_URL,
					site_name: process.env.NEXT_PUBLIC_SITE_NAME,
				}}
			/>
			{isSiteLoaded && (
				<>
					{renderScript && (
						<Script
							strategy="lazyOnload"
							id="Cookiebot"
							src="https://consent.cookiebot.com/uc.js"
							data-cbid="266f03c3-619e-4278-9ea0-072c1b4e36c5"
							type="text/javascript"
						/>
					)}
					<GoogleTagManager gtmId="GTM-KGVXM37" />
				</>
			)}

			<main className={`${montserrat.className} ${assistant.variable}`}>
				<Component {...pageProps} />
			</main>
		</>
	);
}

const ConnectedMyApp = connect(null, { setLanguage, agreeCookieNotice })(App);

function AppWithProvider({ Component, pageProps, router }: AppProps) {
	const store = useStore(pageProps.initialReduxState);
	// const { growthbook, callback: growthbookCallback } = useGrowthbook();
	// store.dispatch(setGrowthbookCallback(growthbookCallback));

	return (
		// <GrowthBookProvider growthbook={growthbook}>
		<Provider store={store}>
			{/* <GrowthbookFeature featureId="sf-google-signin"> */}
			{/* {!Helper.isMobile() && <GoogleSignin />} */}
			{/* </GrowthbookFeature> */}
			<ConnectedMyApp Component={Component} pageProps={pageProps} router={router} />
		</Provider>
		// </GrowthBookProvider>
	);
}

export default AppWithProvider;
