var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1739519456915"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from '@sentry/nextjs';

import { init } from '@sentry/nextjs';

init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	debug: false,
	replaysOnErrorSampleRate: 0,
	tracesSampleRate: 0,
	replaysSessionSampleRate: 0,

	ignoreErrors: [
		'ResizeObserver loop limit exceeded',
		'ResizeObserver loop completed with undelivered notifications.',
		"Can't find variable: _AutofillCallbackHandle",
		'getRestrictions is not a function',
		"Can't find variable: fbq",
		'fbq is not defined',
		'Non-Error promise rejection captured with value: Object Not Found',
		'Non-Error exception captured',
		'Non-Error promise rejection captured',
		'Error: ib',
		"Cannot read properties of null (reading 'readyState')",
	],
	environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
});
