import { de, enGB, es, fr, it, pt } from 'date-fns/locale';

export const localeMap: Record<string, Locale> = { 'en-GB': enGB, es, de, fr, it, pt };

export const PassengerOptions = {
	adults: {
		min: 1,
		max: 9,
	},
	children: {
		min: 0,
		max: 9,
	},
};

export const VERTICAL = 'flights';

export enum TripType {
	ONE_WAY = 'one-way',
	ROUND_TRIP = 'round-trip',
}

export const DEFAULT_LOCALE = 'en-GB';

export type SelectItem = { value: string; label: string };

export const cookieCountries = [
	'BG', // Bulgaria
	'CZ', // Czech Republic
	'HU', // Hungary
	'MD', // Moldova
	'PL', // Poland
	'RO', // Romania
	'SK', // Slovakia
	'DK', // Denmark
	'EE', // Estonia
	'FI', // Finland
	'IE', // Ireland
	'LV', // Latvia
	'LT', // Lithuania
	'SE', // Sweden
	'GB', // UK (Great Britain)
	'HR', // Croatia
	'GR', // Greece
	'IT', // Italy
	'MT', // Malta
	'PT', // Portugal
	'SI', // Slovenia
	'ES', // Spain
	'AT', // Austria
	'BE', // Belgium
	'FR', // France
	'DE', // Germany
	'LU', // Luxembourg
	'NL', // Netherlands
];
